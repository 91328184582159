import {
  Box,
  Hidden,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  useMemo,
  useState,
} from 'react';
import { getSidebarSections } from '@config';
import MainSidebar from '@components/MainSidebar';
import MainNavbar from '../MainNavbar';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
}));

export default function MainLayout(props) {
  const { children } = props;

  const router = useRouter();
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sections = useMemo(() => getSidebarSections(t, router, ['membersortindex']), [t, router]);

  return (
    <>
      <MainLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </MainLayoutRoot>
      <MainNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <Hidden lgUp>
        <MainSidebar
          onClose={() => setIsSidebarOpen(false)}
          open={isSidebarOpen}
          sections={sections}
        />
      </Hidden>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: null,
};
