import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
} from '@mui/material';
import {
  FiMenu,
  FiUsers,
} from 'react-icons/fi';
import { useRef } from 'react';
import NextLink from 'next/link';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import LanguageButton from '@components/i18n/LanguageButton';
import MainNavbarContentLinks from '@components/MainNavbarContentLinks';
import logo from '../../../public/images/logo.svg';

const MainNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
      boxShadow: theme.shadows[3],
    }
    : {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      boxShadow: 'none',
    }),
}));

export default function MainNavbar(props) {
  const { onOpenSidebar, ...other } = props;
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const router = useRouter();
  const { pathname } = router;

  return (
    <MainNavbarRoot ref={anchorRef} {...other}>
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            mr: 2,
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <FiMenu />
        </IconButton>
        <Box sx={{ width: 140, mr: 2 }}>
          <NextLink href="/">
            <Image
              alt=""
              height={46}
              layout="responsive"
              src={logo}
              style={{ display: 'block' }}
              width={180}
            />
          </NextLink>
        </Box>
        <MainNavbarContentLinks />
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Hidden smDown>
            <LanguageButton />
            <NextLink
              href="/member"
              legacyBehavior
              passHref
            >
              <Button
                component="a"
                size="small"
                startIcon={<FiUsers />}
                variant={isSelected(pathname, '/member') ? 'contained' : 'text'}
              >

                {t('components.MainNavbar.member')}
              </Button>
            </NextLink>
          </Hidden>
          <Hidden smUp>
            <NextLink
              href="/member"
              legacyBehavior
              passHref
            >
              <IconButton
                color="primary"
                component="a"
                size="small"
              >
                <FiUsers />
              </IconButton>
            </NextLink>
          </Hidden>
        </Box>
      </Toolbar>
    </MainNavbarRoot>
  );
}

MainNavbar.propTypes = {};

MainNavbar.defaultProps = {};

function isSelected(path, prefix) {
  return path.startsWith(prefix);
}
