import Head from 'next/head';
import MainLayout from '@components/layouts/MainLayout';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  DEFAULT_VIEW,
  DEFAULT_VIEW_MOBILE,
} from '@modules/projects/config';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export default function Home() {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Route user to default view
    router.push(`/organizations/${isMobile ? DEFAULT_VIEW_MOBILE : DEFAULT_VIEW}`);
  }, [router, isMobile]);

  return (
    <Head>
      <title />
    </Head>
  );
}

Home.getLayout = (page) => (
  <MainLayout>
    {page}
  </MainLayout>
);
